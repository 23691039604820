import React from 'react';
import { loadStripe } from '@stripe/stripe-js';

global.stripePromise = loadStripe(process.env.STRIPE_KEY);

function PageWrapper({ children }) {
  return (
    <>
      {children}
    </>
  );
}

export default PageWrapper;